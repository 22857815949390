const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://ffstw9gz81.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://d3hau1t1e6.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://43c9o1igej.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.11.2',
    HOSTNAME: 'https://locations.staging.qberisktoesg.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.qberisktoesg.forwoodsafety.com',
    WEBSOCKET: 'wss://vrob03hdgk.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;